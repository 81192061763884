import {
  SELECT_INPUT,
  L_NUMERIC_INPUT,
} from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_PHYSICAL_PROPERTIES = { action: 'change', subject: 'Physical Properties' }
  const fields = {
    width: {
      type: L_NUMERIC_INPUT,
      label: 'Width',
      unitName: 'name_length',
      factorPhy: 'factor_length',
    },
    depth: {
      type: L_NUMERIC_INPUT,
      label: 'Depth',
      unitName: 'name_length',
      factorPhy: 'factor_length',
    },
    height: {
      type: L_NUMERIC_INPUT,
      label: 'Height',
      unitName: 'name_length',
      factorPhy: 'factor_length',
    },
    weight: {
      type: L_NUMERIC_INPUT,
      label: 'Weight',
      unitName: 'name_weight',
      factorPhy: 'factor_weight',
    },
    measurement_unit_id: {
      type: SELECT_INPUT,
      label: 'Type of units',
      store: 'measurementUnitsList',
      rules: 'required',
    },
    inner_measurement_width: {
      type: L_NUMERIC_INPUT,
      label: 'Width',
      unitName: 'name_length',
      factorPhy: 'factor_length',
      defaultValue: 0,
      rules: 'required',
    },
    inner_measurement_height: {
      type: L_NUMERIC_INPUT,
      label: 'Height',
      unitName: 'name_length',
      factorPhy: 'factor_length',
      defaultValue: 0,
      rules: 'required',
    },
    inner_measurement_depth: {
      type: L_NUMERIC_INPUT,
      label: 'Depth',
      unitName: 'name_length',
      factorPhy: 'factor_length',
      defaultValue: 0,
      rules: 'required',
    },
  }
  return {
    fields,
    ACCESS_ABILITY_PHYSICAL_PROPERTIES,
  }
}
