export const TEXT_INPUT = 'l-text-input'

export const SELECT_INPUT = 'l-select'

export const TEXTAREA_INPUT = 'l-textarea'

export const V_SELECT_INPUT = 'l-select'

export const SELECT_CHILD_INPUT = 'l-select-child'

export const FILE_INPUT = 'file-input-component'

export const CHECKBOX = 'l-checkbox'
export const CHECKBOX_LEF = 'l-checkbox-lf'

export const CHECKBOX_LEFT = 'l-checkbox-left'

export const PASSWORD_INPUT = 'l-password-input'

export const LDATAPICKER = 'l-data-picker'

export const FLAT_PICKER = 'l-flat-picker'

export const TEXT_INPUT_VALID = 'l-text-input-validator'

export const L_TAGS_INPUT = 'l-tags-input'
export const L_TAG_INPUT = 'l-tag-input'

export const L_NUMERIC_INPUT = 'l-numeric-input'

export const RADIO_GROUP = 'l-radio-group'

export const MASK_INPUT = 'l-mask-input'

export const L_CURRENCY_MASK_INPUT = 'l-currency-mask-input'

export const WYSIWYG_EDITOR = 'wysiwyg-editor'

export const L_ACTIVE_CHECK_BOX = 'l-active-check-box'
export const L_ACTIVE_CHECK_LF_BOX = 'l-active-check-lf-box'

export const L_PHONE_MASK_INPUT = 'l-phone-mask-input'

export const L_DATE_BIRTH_MASK_INPUT = 'l-date-birth-mask-input'

export const L_TIME_PICKER_INPUT = 'l-time-picker-input'
