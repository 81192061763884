<template>
  <b-row>
    <b-col md="6">
      <div
        v-for="field in ['measurement_unit_id']"
        :key="field"
        style="height: 70px"
      >
        <span class="inputLabel">{{ fields[field].label }}</span>
        <b-skeleton
          height="40px"
          style="border-radius: 5px"
        />
      </div>
      <b-col md="12">
        <label class="contacts-label font-weight-bolder pl-0">{{ $t('Measurements') }}</label>
        <b-row class="d-flex justify-content-between">
          <div
            v-for="field in ['width', 'depth']"
            :key="field"
            style="height: 70px"
            class="d-flex align-items-center"
          >
            <span
              class="mr-1"
              style="margin-left: 10px"
            >{{ fields[field].label }}</span>
            <b-skeleton
              height="40px"
              width="150px"
              style="border-radius: 5px; margin-bottom: 0"
            />
          </div>
        </b-row>
      </b-col>
      <b-col md="12">
        <b-row class="d-flex justify-content-between">
          <div
            v-for="field in ['height']"
            :key="field"
            style="height: 70px"
            class="d-flex align-items-center"
          >
            <span
              class="mr-1"
              style="margin-left: 10px"
            >{{ fields[field].label }}</span>
            <b-skeleton
              height="40px"
              width="150px"
              style="border-radius: 5px; margin-bottom: 0"
            />
          </div>
        </b-row>
      </b-col>
      <div v-if="getCatalog.type.name === 'Stock container'">
        <b-col md="12">
          <label class="contacts-label font-weight-bolder pl-0">{{ $t('Inner Measurements') }}</label>
          <b-row class="d-flex justify-content-between">
            <div
              v-for="field in ['inner_measurement_width', 'inner_measurement_depth']"
              :key="field"
              style="height: 70px"
              class="d-flex align-items-center"
            >
              <span
                class="mr-1"
                style="margin-left: 10px"
              >{{ fields[field].label }}</span>
              <b-skeleton
                height="40px"
                width="150px"
                style="border-radius: 5px; margin-bottom: 0"
              />
            </div>
          </b-row>
        </b-col>
        <b-col md="12">
          <b-row class="d-flex justify-content-between">
            <div
              v-for="field in ['inner_measurement_height']"
              :key="field"
              style="height: 70px"
              class="d-flex align-items-center"
            >
              <span
                class="mr-1"
                style="margin-left: 10px"
              >{{ fields[field].label }}</span>
              <b-skeleton
                height="40px"
                width="150px"
                style="border-radius: 5px; margin-bottom: 0"
              />
            </div>
          </b-row>
        </b-col>
      </div>
    </b-col>
    <b-col md="6">
      <label class="contacts-label font-weight-bolder">{{ $t('Weight') }}</label>
      <b-row class="d-flex justify-content-between">
        <div
          v-for="field in ['weight']"
          :key="field"
          style="height: 70px"
          class="d-flex align-items-center"
        >
          <span class="inputLabel mr-1">{{ fields[field].label }}</span>
          <b-skeleton
            height="40px"
            width="150px"
            style="border-radius: 5px; margin-bottom: 0"
          />
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'
import config from '../phyPropertiesConfig'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  computed: {
    getCatalog() {
      return this.$store.state[this.CATALOG_MODULE_NAME].catalog
    },
  },
  setup() {
    const CATALOG_MODULE_NAME = 'catalogs'
    const { fields } = config()
    return {
      fields,
      CATALOG_MODULE_NAME,
    }
  },
}
</script>
