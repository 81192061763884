<template>
  <div>
    <skeleton v-if="loading" />
    <phy-properties-form
      v-else
      ref="phy-properties-form"
      @refetchData="refetchData"
    />
  </div>
</template>

<script>
import PhyPropertiesForm from '@/views/main/catalog/view/phy-properties/components/PhyPropertiesForm.vue'
import config from '@/views/main/catalog/view/phy-properties/phyPropertiesConfig'
import Skeleton from './components/Skeleton.vue'

export default {
  name: 'PhyPropertiesView',
  components: {
    Skeleton,
    PhyPropertiesForm,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    getPhyProperties() {
      return this.$store.state[this.MODULE_NAME].phyProperties
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['phy-properties-form']) this.$refs['phy-properties-form'].showConfirmationModal(next)
  },
  created() {
    this.refetchData()
  },
  methods: {
    refetchData() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getPhy`, this.$route.params?.id).then(res => {
        let { data } = res.data
        data = data ?? {}
        const phyPoreperty = this.mappingFields(Object.keys(this.fields), data, {
          // Aim: Second item should be selected on Dropdown,
          // Some hard codes used, but can be resolved later.
          measurement_unit_id: (data?.measurement_unit) ? (data?.measurement_unit) : {
            id: 3,
            name: 'Inches, pounds',
            measure_category_unit_id: 1,
            factor_length: '2.54',
            factor_weight: '0.453592',
            name_length: 'in',
            name_weight: 'lbs',
          },
          container_type_id: data?.container_type,
          depth: this.formatDivision(this.getPhyProperties.depth, this.getPhyProperties.measurement_unit?.factor_length),
          width: this.formatDivision(this.getPhyProperties.width, this.getPhyProperties.measurement_unit?.factor_length),
          height: this.formatDivision(this.getPhyProperties.height, this.getPhyProperties.measurement_unit?.factor_length),
          weight: this.formatDivision(this.getPhyProperties.weight, this.getPhyProperties.measurement_unit?.factor_weight),
          inner_measurement_width: this.formatDivision(this.getPhyProperties.inner_measurement_width, this.getPhyProperties.measurement_unit?.factor_length),
          inner_measurement_height: this.formatDivision(this.getPhyProperties.inner_measurement_height, this.getPhyProperties.measurement_unit?.factor_length),
          inner_measurement_depth: this.formatDivision(this.getPhyProperties.inner_measurement_depth, this.getPhyProperties.measurement_unit?.factor_length),
        })

        this.$store.commit(`${this.MODULE_NAME}/SET_PHY_PROPERTIES_FORM`, phyPoreperty)
        this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_PHY_PROPERTIES_FORM_CLONE`, phyPoreperty)
      }).catch(err => {
        this.error = true
        this.errorNotification(this, err)
      }).finally(() => {
        this.loading = false
      })
    },

    formatDivision(up, down) {
      if (down !== 0) {
        return (up / down).toFixed(3)
      }
      return 0
    },
  },

  setup() {
    const MODULE_NAME = 'phy-properties'
    const CATALOG_MODULE_NAME = 'catalogs'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME,
      fields,
      CATALOG_MODULE_NAME,
      MODULE_NAME_CLONE,
    }
  },
}
</script>
