<template>
  <b-alert
    v-if="Object.keys(error).length > 0"
    ref="LErrorAlert"
    show
    :variant="variant"
    class="p-2 l-error-alert"
  >
    <p
      v-for="(errorField, field) in errors"
      :key="field"
      class="mb-0"
    >
      <template v-if="isLabelsProvided">
        {{ `${ fields[field]? fields[field].label: field } - ${ errorField.join(' ') }` }}
      </template>
      <template v-else>
        {{ `${field} - ${errorField.join(' ')}` }}
      </template>

    </p>
  </b-alert>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import { isUndefined } from '@core/components/b-table-responsive/table-responsive/table/utils/inspect'

export default {
  name: 'ErrorAlert',
  components: {
    BAlert,
  },
  props: {
    error: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      default: 'danger',
    },
    fields: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    message() {
      return this.error.message ?? ''
    },
    errors() {
      return !isUndefined(this.error.errors) ? this.error.errors : { message: [this.error.message] }
    },
    isLabelsProvided() {
      return (this.fields && typeof this.fields === 'object')
    },
  },
  watch: {
    'error.message': function () {
      this.$nextTick(() => {
        if (!this.$refs.LErrorAlert) return

        this.$refs.LErrorAlert.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
