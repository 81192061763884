<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="phyPropertiesCreateVal"
      >
        <b-row>
          <b-col md="6">
            <component
              :is="fields[field].type"
              v-for="field in ['measurement_unit_id']"
              :key="field"
              :ref="`${field}_ref`"
              v-model="phyProperties[field]"
              v-bind="getProps(field)"
              :is-editable="!$can(ACCESS_ABILITY_PHYSICAL_PROPERTIES.action, ACCESS_ABILITY_PHYSICAL_PROPERTIES.subject) || isEdit"
            />
            <div>
              <label class="contacts-label font-weight-bolder mb-1">{{
                $t('Measurements')
              }}</label>
              <b-row>
                <b-col md="6">
                  <component
                    :is="fields[field].type"
                    v-for="field in ['width', 'height']"
                    :key="field"
                    :ref="`${field}_ref`"
                    v-model="phyProperties[field]"
                    v-bind="getProps(field)"
                    :is-editable="!$can(ACCESS_ABILITY_PHYSICAL_PROPERTIES.action, ACCESS_ABILITY_PHYSICAL_PROPERTIES.subject) || isEdit"
                  />
                </b-col>
                <b-col md="6">
                  <component
                    :is="fields[field].type"
                    v-for="field in ['depth']"
                    :key="field"
                    :ref="`${field}_ref`"
                    v-model="phyProperties[field]"
                    v-bind="getProps(field)"
                    :is-editable="!$can(ACCESS_ABILITY_PHYSICAL_PROPERTIES.action, ACCESS_ABILITY_PHYSICAL_PROPERTIES.subject) || isEdit"
                  />
                </b-col>
              </b-row>
            </div>
            <div v-if="getCatalog.type.id === productTypeStock">
              <label class="contacts-label font-weight-bolder mb-1">{{
                $t('Inner Measurements')
              }}</label>
              <b-row>
                <b-col md="6">
                  <component
                    :is="fields[field].type"
                    v-for="field in ['inner_measurement_width', 'inner_measurement_height']"
                    :key="field"
                    :ref="`${field}_ref`"
                    v-model="phyProperties[field]"
                    v-bind="getProps(field)"
                    :is-editable="true"
                  />
                  <!--                    :is-editable="!$can(ACCESS_ABILITY_PHYSICAL_PROPERTIES.action, ACCESS_ABILITY_PHYSICAL_PROPERTIES.subject)"-->
                </b-col>
                <b-col md="6">
                  <component
                    :is="fields[field].type"
                    v-for="field in ['inner_measurement_depth']"
                    :key="field"
                    :ref="`${field}_ref`"
                    v-model="phyProperties[field]"
                    v-bind="getProps(field)"
                    :is-editable="!$can(ACCESS_ABILITY_PHYSICAL_PROPERTIES.action, ACCESS_ABILITY_PHYSICAL_PROPERTIES.subject) || isEdit"
                  />
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col md="6">
            <div>
              <b-col md="6">
                <label class="contacts-label font-weight-bolder">{{
                  $t('Weight')
                }}</label>
                <component
                  :is="fields[field].type"
                  v-for="field in ['weight']"
                  :key="field"
                  :ref="`${field}_ref`"
                  v-model="phyProperties[field]"
                  v-bind="getProps(field)"
                  :is-editable="!$can(ACCESS_ABILITY_PHYSICAL_PROPERTIES.action, ACCESS_ABILITY_PHYSICAL_PROPERTIES.subject) || isEdit"
                />
              </b-col>
            </div>
          </b-col>
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="cancel"
              >
                {{ $t('Back to List') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                v-if="$can(ACCESS_ABILITY_PHYSICAL_PROPERTIES.action, ACCESS_ABILITY_PHYSICAL_PROPERTIES.subject)"
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>
<script>
import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BButton, BCol, BForm, BRow, VBTooltip,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import store from '@/store'
import config from '../phyPropertiesConfig'

export default {
  name: 'PhyPropertiesForm',
  components: {
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      error: {},
      required,
      isSubmit: false,
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    phyPropertiesFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].phyPropertiesFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.phyProperties) !== this.phyPropertiesFormClone
    },
    phyProperties() {
      return this.$store.state[this.MODULE_NAME].phyPropertiesForm
    },
    productTypeStock() {
      return this.$store.state[this.MODULE_NAME].productTypeStock
    },
    getCatalog() {
      return this.$store.state[this.CATALOG_MODULE_NAME].catalog
    },
    measurementUnitId() {
      return this.phyProperties.measurement_unit_id
    },
    getPhyProperties() {
      return this.$store.state[this.MODULE_NAME].phyProperties
    },
  },
  watch: {
    measurementUnitId(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.$store.commit(`${this.MODULE_NAME}/CHANGE_VALUE_BY_UNITE_TYPE`, {
          oldUnitType: oldValue,
          newUnitType: newValue,
        })
      }
    },
  },
  methods: {
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.isSubmit)
    },
    submit() {
      this.error = {}
      this.isSubmit = true

      this.$refs.phyPropertiesCreateVal.validate()
        .then(success => {
          if (success) {
            const data = this.phyProperties
            const phyProperties = this.mappingFields(Object.keys(this.fields), data, {
              product: this.getCatalog.id,
              measurement_unit_id: data.measurement_unit_id.id,
              width: this.formatDivision(this.phyProperties.width, this.phyProperties.measurement_unit_id.factor_length),
              depth: this.formatDivision(this.phyProperties.depth, this.phyProperties.measurement_unit_id.factor_length),
              height: this.formatDivision(this.phyProperties.height, this.phyProperties.measurement_unit_id.factor_length),
              weight: this.formatDivision(this.phyProperties.weight, this.phyProperties.measurement_unit_id.factor_weight),
              inner_measurement_width: this.getCatalog.type.id === this.productTypeStock ? this.formatDivision(this.phyProperties.inner_measurement_width, this.phyProperties.measurement_unit_id.factor_length) : 0,
              inner_measurement_height: this.getCatalog.type.id === this.productTypeStock ? this.formatDivision(this.phyProperties.inner_measurement_height, this.phyProperties.measurement_unit_id.factor_length) : 0,
              inner_measurement_depth: this.getCatalog.type.id === this.productTypeStock ? this.formatDivision(this.phyProperties.inner_measurement_depth, this.phyProperties.measurement_unit_id.factor_length) : 0,
            })
            this.sendNotification(this, phyProperties, `${this.MODULE_NAME}/updatePhy`).then(() => {
              this.$emit('refetchData')
              this.$router.push({
                name: 'home-catalog-components',
                params: { id: this.getCatalog?.id },
              })
            }).catch(err => {
              this.error = err.response?.data
            })
          } else {
            scrollToError(this, this.$refs.phyPropertiesCreateVal)
          }
        })
    },
    clear() {
      this.$refs.phyPropertiesCreateVal.reset()
      this.$store.commit(`${this.MODULE_NAME}/SET_PHY_PROPERTIES_FORM`, {})
      this.$router.push({ name: 'home-main-catalog-list' })
    },
    cancel() {
      this.$router.push({ name: 'home-main-catalog-list' })
    },
    loader() {
      this.$refs.phyPropertiesCreateVal.reset()
      const smth = JSON.parse(this.phyPropertiesFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_PHY_PROPERTIES_FORM`, smth)
    },
    getProps(fieldName) {
      const props = {
        is: this.fields[fieldName].type,
        field: this.fields[fieldName],
        name: fieldName,
      }
      return props
    },

    formatDivision(first, second) {
      return Number(first * second).toFixed(3)
    },
    isEditAble() {
      this.isEdit = !this.isEdit
    },
  },
  setup() {
    const MODULE_NAME = 'phy-properties'
    const CATALOG_MODULE_NAME = 'catalogs'
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields, ACCESS_ABILITY_PHYSICAL_PROPERTIES } = config()
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      fields,
      CATALOG_MODULE_NAME,
      ACCESS_ABILITY_PHYSICAL_PROPERTIES,
    }
  },
}
</script>
